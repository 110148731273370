{
  "no_data": {
    "page_notfound": "Sorry, this page is not available",
    "page_notfound_message": " Sorry, this page does not exist, it may have been deleted, or moved to a new location.",
    "backhome": "Back home"
   
  },
  "forms": {
    "name":{
      "label":"Name",
      "placeholder": "Please write your name"
    },
    "description":{
      "label": "Project Description",
      "placeholder": "Please enter a brief description of your project"
    },
    "phone_number": {
      "label": "phone number",
      "placeholder": "Please enter phone number"
    },
    "email": {
      "label": "email",
      "placeholder":"Please enter a contact email"
    },
    "entity_type":{
      "label":"Entity type",
      "placeholder":"Please choose entity type"

    }
   
  },

  "modal": {
    "success_title":"Sent",
    "success_subtitle":"Your request has been sent successfully, our customer service team will contact you as soon as possible ^_^ We are happy to serve you"
  }
 
}
