import uiux from "./uiux.svg?component";
import logo from "../logo.svg?component";
import downloadIcon from "./download.svg?component";
import instaIcon from "./insta.svg?component";
import twitterIcon from "./twitter.svg?component";
import mailIcon from "./mail.svg?component";
import phoneIcon from "./phone.svg?component";
import facebookIcon from "./facebook.svg?component";
import linkedinIcon from "./linkedin.svg?component";
import arrowRightIcon from "./arrow-right.svg?component";
import successIcon from "./success.svg?component";
import contactUs from "./contact-us.svg?component";

const svgComponents = (app) => {
  return app
    .component("logo", logo)
    .component("uiux", uiux)
    .component("contact-us", contactUs)
    .component("download-icon", downloadIcon)
    .component("mail-icon", mailIcon)
    .component("phone-icon", phoneIcon)
    .component("facebook-icon", facebookIcon)
    .component("linkedin-icon", linkedinIcon)
    .component("insta-icon", instaIcon)
    .component("twitter-icon", twitterIcon)
    .component("arrow-right-icon", arrowRightIcon)
    .component("success-icon", successIcon);
};
export default svgComponents;
