<script setup>
import { WameedButton } from "wameed-ui";
import { useRouter } from "vue-router";
const router = useRouter();
</script>

<template>
  <div class="hero-section">
    <!-- <not-found class="mb-5" /> -->
    <h2 class="contact-title">
      {{ $t("no_data.page_notfound") }}
    </h2>
    <h3 class="contact-subtitle">
      {{ $t("no_data.page_notfound_message") }}
    </h3>
    <wameed-button
      @onClick="
        router.push({
          name: 'home',
          params: {
            lang: $i18n.locale,
          },
        })
      "
      variant="primary"

      :title="$t('no_data.backhome')"
      pill
      classes=" btn-primary text-med-16 text-white mt-4 "
      block
    />
  </div>
</template>
