{
  "home_title":"We create  digital products<br /> to improve <strong>people's lives</strong>",
  "home_subtitle":"We specialize in software development<br />and designing high-quality digital products",
  "start_project": "Start your project now",
  "download_portfolio":"Download Portfolio",
  "contact_us_with": "Contact us",
  "social_media": "Follow us",
  "wameed_rights":"All rights reserved © wameed 2023",
  "go_back_home":"go back to home",
  "contact_title":"Welcome <label dir='ltr'>👋</label>",
  "contact_subtitle":"Please enter the following information<br />to schedule your request and we will contact you soon",
  "confirm": "home",
  "cancel": "close",
  "send": "send"
}
