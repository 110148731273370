<script setup>

</script>
<template>
  <div class="main" id="wameed">
    <router-view v-slot="{ Component }">
      <!-- <transition name="section"> -->
        <component :is="Component" />
      <!-- </transition> -->
    </router-view>
  </div>
</template>
<style lang="scss"> 
.animation-contact-us{

  transition: all 0.5s ease;
  &:lang(en){
    animation: slideOut 1s ;
  animation-fill-mode: forwards;

  }
  &:lang(ar){
    animation: slideOutE 1s;
    animation-fill-mode: forwards;

  }
}
 
@keyframes slideOut {
  from {
    transform: translateX(0);
    
  }
  to {
    transform: translateX(-100%);
  }
}

 
@keyframes slideOutE {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);

  }
}
 
.animation-contact-us2{
  transition: all 0.5s ease;
  &:lang(en){
    animation: slideOut2 1s ;
  animation-fill-mode: forwards;

  }
  &:lang(ar){
    animation: slideOut2E 1s;
    animation-fill-mode: forwards;

  }
}

@keyframes slideOut2 {
  0%   {    
    transform: translateX(0);}
  5%  {  transform: translateX(0); }
  100% {  transform: translateX(-100%); }
}

 
@keyframes slideOut2E {
  0%   {  transform: translateX(0); }
  20%  { transform: translateX(0); }
  100% {  transform: translateX(100%); }
  // from {
  //   left: 0;
  // }
  // to {
  //   left: 100%;

  // }
}
</style>
