{
   

  "no_data": {
    "page_notfound": "عذراً ،، هذه الصفحة غير متوفرة",
    "page_notfound_message": " المعذرة، هذه الصفحة غير موجودة، قد تكون حذفت، أو نقلت لمكان جديد.",
    "backhome": "العودة للرئيسية"
   
  },
  "forms": {
    "name":{
      "label":"الاسم",
      "placeholder":"يرجى كتابة اسم مقدم الطلب"
    },
    "description":{
      "label":  "وصف المشروع",
      "placeholder":"نحتاج منك وصف مختصر للمشروع  او للطلب الذي تريده"
    },
    "phone_number": {
      "label": "رقم الهاتف",
      "placeholder":"يرجى إدخال رقم الهاتف للتواصل"
    },
    "email": {
      "label": "البريد الإلكتروني",
      "placeholder":"يرجى إدخال البريد الإلكتروني للتواصل"
    },
    "entity_type":{
      "label":"نوع الجهة",
      "placeholder":"يرجى تحديد نوع الجهة"

    }
   
    
  },

  "modal": {
   "success_title":"تم الإرسال",
   "success_subtitle":"تم إرسال طلبك بنجاح , سيقوم فريق خدمة العملاء بالتواصل معك في اقرب وقت ممكن ^_^ نسعد بخدمتك"


  }
  
}
