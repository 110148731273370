{
 
  "home_title":"نبتكر ونطور منتجات رقمية<br />  لتحسن <strong>حياة الناس</strong>",
  "home_subtitle":"شركة متخصصة في تطوير البرمجيات<br />وتصميم المنتجات الرقمية عالية الجودة",
  "start_project":"ابدأ مشروعك آلان",
  "download_portfolio":"تحميل معرض الاعمال",
  "contact_us_with":"يمكنك التواصل عن طريق",
  "social_media":"نسعد بمتابعتك لنا على",
  "wameed_rights":"جميع الحقوق محفوظة © وميض 2023",
  "go_back_home":"العودة للرئيسية",
  "contact_title":"أهلاً و سهلا   <label dir='ltr'>👋</label>",
  "contact_subtitle":"يرجى إدخال المعلومات التالية<br />ليتم جدولة طلبك و التواصل مع قريبا",
  "confirm": "الرئيسية",
  "cancel": "إغلاق",
  "send": "ارسال"



}
